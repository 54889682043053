import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'

import App from './App'
import decisionApp from './reducers'

import 'semantic-ui-css/semantic.min.css'
import './index.css'

// const loggerMiddleware = createLogger()
const store = createStore(
  decisionApp,
  applyMiddleware(
    thunkMiddleware,
    //loggerMiddleware
  )
)

// window.LOG_LEVEL = 'DEBUG'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'))
