import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import content from './content'
import survey from './survey'
import recaptcha from './recaptcha'
import response from './response'
import results from './results'
import feedback from './feedback'

const sixagonalApp = combineReducers({
  content,
  survey,
  recaptcha,
  response,
  results,
  feedback,
  form: formReducer
})

export default sixagonalApp
