import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import Analytics from 'react-router-ga'

import asyncComponent from "./components/AsyncComponent";
import ScrollToTop from "./components/ScrollToTop";
import AppHeader from "./components/AppHeader";

import config from './config'

const AsyncNotFound = asyncComponent(() => import("./pages/NotFound"));
const AsyncHome = asyncComponent(() => import("./pages/Welcome"));
const AsyncSurveyType = asyncComponent(() => import("./pages/SurveyType"));
const AsyncNewSprintSurvey = asyncComponent(() => import("./pages/CreateSprintSurvey/CreateSprintSurveyContainer"));
const AsyncManageSprintSurvey = asyncComponent(() => import("./pages/ManageSprintSurvey/ManageSprintSurveyContainer"));
const AsyncResultsSprintSurvey = asyncComponent(() => import("./pages/ResultsSprintSurvey/ResultsSprintSurveyContainer"));
const AsyncRespondSprintSurvey = asyncComponent(() => import("./pages/RespondSprintSurvey/RespondSprintSurveyContainer"));
const AsyncThankyou = asyncComponent(() => import("./pages/Thankyou"));
const AsyncTerms = asyncComponent(() => import("./pages/Content/Terms"));
const AsyncAbout = asyncComponent(() => import("./pages/Content/About"));
const AsyncContact = asyncComponent(() => import("./pages/Contact"));
const AsyncContent = asyncComponent(() => import("./pages/CmsContent/CmsContentContainer"));

export default class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Analytics id={config.ga}>
          <AppHeader />
          <ScrollToTop>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              <Route
                path="/"
                exact
                component={AsyncHome}
              />
              <Route
                path="/surveys/create"
                exact
                component={AsyncSurveyType}
              />
              <Route
                path="/surveys/create/sprint"
                exact
                component={AsyncNewSprintSurvey}
              />
              <Route
                path="/surveys/manage/sprint/:id"
                exact
                component={AsyncManageSprintSurvey}
              />
              <Route
                path="/surveys/manage/sprint/:id/results"
                exact
                component={AsyncResultsSprintSurvey}
              />
              <Route
                path="/surveys/results/sprint/:id"
                exact
                component={AsyncResultsSprintSurvey}
              />

              <Route
                path="/surveys/respond/sprint/:id"
                exact
                component={AsyncRespondSprintSurvey}
              />

              <Route
                path="/surveys/respond/sprint/:id/thank-you"
                exact
                component={AsyncThankyou}
              />
               <Route
                path="/terms"
                exact
                component={AsyncTerms}
              />
              <Route
                path="/about"
                exact
                component={AsyncAbout}
              />
              <Route
                path="/contact"
                exact
                component={AsyncContact}
              />

              <Route
                path="/content/:slug"
                exact
                component={AsyncContent}
              />
              <Route component={AsyncNotFound} />
            </AnimatedSwitch>
          </ScrollToTop>
        </Analytics>
      </BrowserRouter>
    )
  }
}
