import { API } from '../services/sixagonalApiService'

/*
 * action types
 */

export const REQUEST_VERIFICATION = 'REQUEST_VERIFICATION'
export const RECEIVE_VERIFICATION = 'RECEIVE_VERIFICATION'
export const VERIFICATION_FAILED = 'VERIFICATION_FAILED'
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'

/*
 * action creators
 */

export function requestVerification () {
  return { type: REQUEST_VERIFICATION }
}

export function receiveVerification (verification) {
  return { type: RECEIVE_VERIFICATION, verification }
}

export function verificationFailed (failure) {
  return { type: VERIFICATION_FAILED, failure }
}

export function receiveToken (token) {
  return { type: RECEIVE_TOKEN, token }
}

/*
 * async action creators
 */

export function verifyToken (token, action) {
  return function (dispatch) {
    dispatch(requestVerification())
    API.verifyToken(token, action)
      .then(data => dispatch(receiveVerification(data)))
      .catch(err => dispatch(verificationFailed(err)))
  }
}
