import { API } from '../services/sixagonalApiService'

/*
 * action types
 */

export const REQUEST_RESULTS = 'REQUEST_RESULTS'
export const RECEIVE_RESULTS = 'RECEIVE_RESULTS'
export const RESULTS_LOADING_FAILED = 'RESULTS_LOADING_FAILED'

/*
 * action creators
 */

export function requestResults (id) {
  return { type: REQUEST_RESULTS, id }
}

export function receiveResults (survey) {
  return { type: RECEIVE_RESULTS, survey }
}

export function resultsFailed (failure) {
  return { type: RESULTS_LOADING_FAILED, failure }
}

/*
 * async action creators
 */

export function fetchResults (id, shared = false) {
  return function (dispatch) {
    dispatch(requestResults(id))
    const apiCall = shared ? 'fetchSurveyResultsByShare' : 'fetchSurveyResults'
    API[apiCall](id)
      .then(data => dispatch(receiveResults(data)))
      .catch(err => dispatch(resultsFailed(err)))
  }
}
