import { API } from '../services/sixagonalApiService'

/*
 * action types
 */

export const REQUEST_SURVEY = 'REQUEST_SURVEY'
export const RECEIVE_SURVEY = 'RECEIVE_SURVEY'
export const SURVEY_LOADING_FAILED = 'SURVEY_LOADING_FAILED'

export const CREATE_SURVEY_FAILED = 'CREATE_SURVEY_FAILED'
export const CREATED_SURVEY = 'CREATED_SURVEY'
export const CREATE_SURVEY = 'CREATE_SURVEY'

export const CLOSE_SURVEY_FAILED = 'CLOSE_SURVEY_FAILED'
export const CLOSED_SURVEY = 'CLOSED_SURVEY'
export const CLOSE_SURVEY = 'CLOSE_SURVEY'

export const UPDATE_SURVEY_FAILED = 'UPDATE_SURVEY_FAILED'
export const UPDATED_SURVEY = 'UPDATED_SURVEY'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'

export const DELETE_SURVEY_RESPONSE_FAILED = 'DELETE_SURVEY_RESPONSE_FAILED'
export const DELETED_SURVEY_RESPONSE = 'DELETED_SURVEY_RESPONSE'
export const DELETE_SURVEY_RESPONSE = 'DELETE_SURVEY_RESPONSE'

/*
 * action creators
 */

export function requestSurvey (id) {
  return { type: REQUEST_SURVEY, id }
}

export function receiveSurvey (survey) {
  return { type: RECEIVE_SURVEY, survey }
}

export function loadingFailed (failure) {
  return { type: SURVEY_LOADING_FAILED, failure }
}

export function updateFailed (failure) {
  return { type: UPDATE_SURVEY_FAILED, failure }
}

export function updatedSurvey (id, survey) {
  return { type: UPDATED_SURVEY, id, survey }
}

export function updatingSurvey (id) {
  return { type: UPDATE_SURVEY, id }
}

export function createFailed (failure) {
  return { type: CREATE_SURVEY_FAILED, failure }
}

export function createdSurvey (survey) {
  return { type: CREATED_SURVEY, survey }
}

export function creatingSurvey () {
  return { type: CREATE_SURVEY }
}

export function closeFailed (failure) {
  return { type: CLOSE_SURVEY_FAILED, failure }
}

export function closedSurvey () {
  return { type: CLOSED_SURVEY }
}

export function closingSurvey () {
  return { type: CLOSE_SURVEY }
}

export function deleteSurveyResponseFailed (failure) {
  return { type: DELETE_SURVEY_RESPONSE_FAILED, failure }
}

export function deletedSurveyResponse () {
  return { type: DELETED_SURVEY_RESPONSE }
}

export function deletingSurveyResponse () {
  return { type: DELETE_SURVEY_RESPONSE }
}

/*
 * async action creators
 */

export function fetchSurvey (id) {
  return function (dispatch) {
    dispatch(requestSurvey(id))
    API.fetchSurvey(id)
      .then(data => dispatch(receiveSurvey(data)))
      .catch(err => dispatch(loadingFailed(err)))
  }
}

export function updateSurvey (id, body) {
  return function (dispatch) {
    dispatch(updatingSurvey(id))
    API.updateSurvey(id, body)
      .then(data => dispatch(updatedSurvey(id, data)))
      .then(data => dispatch(fetchSurvey(id)))
      .catch(err => dispatch(updateFailed(err)))
  }
}

export function createSurvey (body, history) {
  return function (dispatch) {
    dispatch(creatingSurvey())
    API.createSurvey(body)
      .then(data => dispatch(createdSurvey(data)))
      .then(data => history.push(`/surveys/manage/sprint/${data.survey.id}`))
      .catch(err => dispatch(createFailed(err)))
  }
}

export function closeSurvey (id) {
  return function (dispatch) {
    dispatch(closingSurvey(id))
    API.closeSurvey(id)
      .then(data => dispatch(closedSurvey(data)))
      .then(data => dispatch(fetchSurvey(id)))
      .catch(err => dispatch(closeFailed(err)))
  }
}

export function deleteSurveyResponse (survey, response) {
  return function (dispatch) {
    dispatch(deletingSurveyResponse())
    API.deleteSurveyResponse(survey, response)
      .then(data => dispatch(deletedSurveyResponse(data)))
      .then(data => dispatch(fetchSurvey(survey)))
      .catch(err => dispatch(deleteSurveyResponseFailed(err)))
  }
}

