import axios from 'axios'
// temporary
const config = {
  spaceId: 'wkmlz33xdbpa',
  token: 'ZlarZIVUm8RWrW_L-3qILJsRsg55_DauLKVFpl7ykZs'
}

class ContentfulAPI {
  constructor () {
    let { spaceId, token } = config
    this.api = axios.create({
      baseURL: `https://cdn.contentful.com/spaces/${spaceId}/entries`,
      headers: { Authorization: `Bearer ${token}` }
    })
  }

  async fetchArticleBySlug (slug) {
    try {
      let { data } = await this.api.get(`?content_type=article&fields.slug=${slug}`)
      if (data && data.items.length > 0) return data.items[0]
      throw new Error('No content matching slug.')
    }
    catch (e) {
      throw new Error(e)
    }
  }

}

export let CfAPI = new ContentfulAPI()
