import {
  CREATE_RESPONSE_FAILED,
  CREATED_RESPONSE,
  CREATE_RESPONSE,
  RESPONSE_REQUEST_SURVEY,
  RESPONSE_RECEIVE_SURVEY,
  RESPONSE_SURVEY_LOADING_FAILED
} from '../actions/response'

const initialState = {
  survey: false,
  response: false,
  failure: false,
  creating: false
}

export default function responseReducer (state = initialState, action) {
  switch (action.type) {

    case RESPONSE_REQUEST_SURVEY:
      return Object.assign({}, state, {
        loading: true
      })

    case RESPONSE_RECEIVE_SURVEY:
      return Object.assign({}, state, {
        loading: false,
        survey: action.survey
      })

    case RESPONSE_SURVEY_LOADING_FAILED:
      return Object.assign({}, state, {
        loading: false,
        failure: action.failure
      })

    case CREATED_RESPONSE:
      return Object.assign({}, state, {
        creating: false
      })

    case CREATE_RESPONSE:
      return Object.assign({}, state, {
        creating: true
      })

    case CREATE_RESPONSE_FAILED:
      return Object.assign({}, state, {
        creating: false,
        failure: action.failure
      })

    default:
      return state
  }
}
