import { API } from '../services/sixagonalApiService'

/*
 * action types
 */

export const CREATE_FEEDBACK_FAILED = 'CREATE_FEEDBACK_FAILED'
export const CREATED_FEEDBACK = 'CREATED_FEEDBACK'
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK'
export const SHOW_FEEDBACK_MODAL = 'SHOW_FEEDBACK_MODAL'
export const HIDE_FEEDBACK_MODAL = 'HIDE_FEEDBACK_MODAL'
export const TOGGLE_FEEDBACK_MODAL = 'TOGGLE_FEEDBACK_MODAL'

/*
 * action creators
 */

export function createFailed (failure) {
  return { type: CREATE_FEEDBACK_FAILED, failure }
}

export function createdFeedback () {
  return { type: CREATED_FEEDBACK }
}

export function creatingFeedback () {
  return { type: CREATE_FEEDBACK }
}

export function showFeedbackModal () {
  return { type: SHOW_FEEDBACK_MODAL }
}

export function hideFeedbackModal () {
  return { type: HIDE_FEEDBACK_MODAL }
}

export function toggleFeedbackModal () {
  return { type: TOGGLE_FEEDBACK_MODAL }
}

/*
 * async action creators
 */

export function createFeedback (body) {
  return function (dispatch) {
    dispatch(creatingFeedback())
    API.sendFeedback(body)
      .then(data => dispatch(createdFeedback()))
      .catch(err => dispatch(createFailed(err)))
  }
}

