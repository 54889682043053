import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Container,
  Image,
  Menu,
  Label,
  Button
} from 'semantic-ui-react'

import { showFeedbackModal } from '../actions/feedback'
import SXLogo from '../sx-logo-hex.png'

class AppHeader extends Component {
  render () {
    const { showFeedbackModal, success} = this.props
    return (
      <Menu inverted style={{marginBottom: 0, backgroundColor: '#101010'}}>
        <Container>
          <Menu.Item as={Link} to='/' header>
            <Image size='mini' src={SXLogo} style={{ marginRight: '1.2em', width: '20px' }} />
            Sixagonal <Label color='orange'>BETA</Label>
          </Menu.Item>
          <Menu.Item as={Link} to='/surveys/create'>Create Survey</Menu.Item>
          <Menu.Item as={Link} to='/about'>About</Menu.Item>
          <Menu.Item as={Link} to='/contact'>Contact</Menu.Item>

          <Menu.Menu position='right'>
            <Menu.Item>
              { success && <Button onClick={showFeedbackModal} icon='comment alternate' labelPosition='left' content='Thank you! :)' color='green' /> }
              { !success && <Button onClick={showFeedbackModal} icon='comment alternate' labelPosition='left' content='Give feedback' color='pink' /> }
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    )
  }
}


AppHeader = connect(
  (state, ownProps) => ({
    showModal: state.feedback.showModal,
    success: state.feedback.success
  }),
  dispatch => ({
    showFeedbackModal: () => dispatch(showFeedbackModal())
  })
)(AppHeader)

export default AppHeader