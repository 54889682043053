import { CfAPI } from '../services/contentfulApi'

/*
 * action types
 */

export const REQUEST_CONTENT = 'REQUEST_CONTENT'
export const RECEIVE_CONTENT = 'RECEIVE_CONTENT'
export const CONTENT_FAILED = 'CONTENT_FAILED'

/*
 * action creators
 */

export function requestContent () {
  return { type: REQUEST_CONTENT }
}

export function receiveContent (content) {
  return { type: RECEIVE_CONTENT, content }
}

export function contentFailed (failure) {
  return { type: CONTENT_FAILED, failure }
}

/*
 * async action creators
 */

export function fetchContent (slug) {
  return function (dispatch) {
    dispatch(requestContent())
    CfAPI.fetchArticleBySlug(slug)
      .then(data => dispatch(receiveContent(data)))
      .catch(err => dispatch(contentFailed(err)))
  }
}
