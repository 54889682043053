import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadReCaptcha } from 'react-recaptcha-v3'

import Routes from './Routes'
import config from './config'

class App extends Component {

  componentDidMount () {
    loadReCaptcha(config.recaptcha)
  }

  render () {
    return (
      <div>
        <Routes />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

App = connect(mapStateToProps, mapDispatchToProps)(App)
export default App
