import {
  REQUEST_SURVEY,
  RECEIVE_SURVEY,
  SURVEY_LOADING_FAILED,
  CREATE_SURVEY_FAILED,
  CREATED_SURVEY,
  CREATE_SURVEY,
  CLOSE_SURVEY_FAILED,
  CLOSED_SURVEY,
  CLOSE_SURVEY,
  UPDATE_SURVEY_FAILED,
  UPDATED_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY_RESPONSE,
  DELETED_SURVEY_RESPONSE,
  DELETE_SURVEY_RESPONSE_FAILED
} from '../actions/survey'

const initialState = {
  survey: false,
  loading: false,
  failure: false,
  creating: false,
  updating: false,
  deleting: false,
  closing: false,
  success: false
}

export default function surveyReducer (state = initialState, action) {
  switch (action.type) {

    case REQUEST_SURVEY:
      return Object.assign({}, state, {
        loading: true
      })

    case RECEIVE_SURVEY:
      return Object.assign({}, state, {
        loading: false,
        survey: action.survey
      })

    case SURVEY_LOADING_FAILED:
      return Object.assign({}, state, {
        loading: false,
        failure: action.failure
      })

    case CREATED_SURVEY:
      return Object.assign({}, state, {
        creating: false,
        success: true
      })

    case CREATE_SURVEY:
      return Object.assign({}, state, {
        creating: true,
      })

    case CREATE_SURVEY_FAILED:
      return Object.assign({}, state, {
        creating: false,
        failure: action.failure
      })

    case UPDATED_SURVEY:
      return Object.assign({}, state, {
        updating: false
      })

    case UPDATE_SURVEY:
      return Object.assign({}, state, {
        updating: true
      })

    case UPDATE_SURVEY_FAILED:
      return Object.assign({}, state, {
        updating: false,
        failure: action.failure
      })

    case CLOSED_SURVEY:
      return Object.assign({}, state, {
        closing: false
      })

    case CLOSE_SURVEY:
      return Object.assign({}, state, {
        closing: true
      })

    case CLOSE_SURVEY_FAILED:
      return Object.assign({}, state, {
        closing: false,
        failure: action.failure
      })

    case DELETED_SURVEY_RESPONSE:
      return Object.assign({}, state, {
        deleting: false
      })

    case DELETE_SURVEY_RESPONSE:
      return Object.assign({}, state, {
        deleting: true
      })

    case DELETE_SURVEY_RESPONSE_FAILED:
      return Object.assign({}, state, {
        deleting: false,
        failure: action.failure
      })
    default:
      return state
  }
}
