import axios from 'axios'
import config from '../config'

class SixagonalApiService {

  constructor () {
    let { baseURL } = config
    this.api = axios.create({
      baseURL
    })
  }

  async fetchSurvey (id) {
    try {
      let { data } = await this.api.get(`/surveys/${id}`)
      return data
    }
    catch (e) {
      throw new Error(e)
    }
  }

  async fetchSurveyResults (id) {
    try {
      let { data } = await this.api.get(`/surveys/${id}/results`)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async createSurveyResponse (id, body) {
    try {
      let { data } = await this.api.post(`/surveys/${id}/responses`, body)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async deleteSurveyResponse (survey, response) {
    try {
      let { data } = await this.api.delete(`/surveys/${survey}/responses/${response}`)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async createSurvey (body) {
    try {
      let { data } = await this.api.post(`/surveys`, body)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async updateSurvey (id, body) {
    try {
      let { data } = await this.api.put(`/surveys/${id}`, body)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async closeSurvey (id) {
    try {
      let { data } = await this.api.get(`/surveys/${id}/close`)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async fetchSurveyByShare (id) {
    try {
      let { data } = await this.api.get(`/surveys/shared/${id}`)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async fetchSurveyResultsByShare (id) {
    try {
      let { data } = await this.api.get(`/surveys/shared/${id}/results`)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async sendFeedback (body) {
    try {
      let { data } = await this.api.post(`/feedback`, body)
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

  async verifyToken (token, action) {
    try {
      let { data } = await this.api.post(`/captcha/verify`, { token, action })
      return data
    } catch (e) {
      throw new Error(e)
    }
  }

}

export let API = new SixagonalApiService()
