import { API } from '../services/sixagonalApiService'

/*
 * action types
 */

export const CREATE_RESPONSE_FAILED = 'CREATE_RESPONSE_FAILED'
export const CREATED_RESPONSE = 'CREATED_RESPONSE'
export const CREATE_RESPONSE = 'CREATE_RESPONSE'

export const RESPONSE_REQUEST_SURVEY = 'RESPONSE_REQUEST_SURVEY'
export const RESPONSE_RECEIVE_SURVEY = 'RESPONSE_RECEIVE_SURVEY'
export const RESPONSE_SURVEY_LOADING_FAILED = 'RESPONSE_SURVEY_LOADING_FAILED'

/*
 * action creators
 */

export function createFailed (failure) {
  return { type: CREATE_RESPONSE_FAILED, failure }
}

export function createdResponse (response) {
  return { type: CREATED_RESPONSE, response }
}

export function creatingResponse () {
  return { type: CREATE_RESPONSE }
}

export function requestSurvey (id) {
  return { type: RESPONSE_REQUEST_SURVEY, id }
}

export function receiveSurvey (survey) {
  return { type: RESPONSE_RECEIVE_SURVEY, survey }
}

export function loadingFailed (failure) {
  return { type: RESPONSE_SURVEY_LOADING_FAILED, failure }
}

/*
 * async action creators
 */

export function createResponse (id, body, history) {
  return function (dispatch) {
    dispatch(creatingResponse())
    API.createSurveyResponse(id, body)
      .then(data => dispatch(createdResponse(data)))
      .then(data => history.push(`/surveys/respond/sprint/${id}/thank-you`))
      .catch(err => dispatch(createFailed(err)))
  }
}

export function fetchSurvey (id) {
  return function (dispatch) {
    dispatch(requestSurvey(id))
    API.fetchSurveyByShare(id)
      .then(data => dispatch(receiveSurvey(data)))
      .catch(err => dispatch(loadingFailed(err)))
  }
}

export function fetchSurveyResults (id) {
  return function (dispatch) {
    dispatch(requestSurvey(id))
    API.fetchSurveyResultsByShare(id)
      .then(data => dispatch(receiveSurvey(data)))
      .catch(err => dispatch(loadingFailed(err)))
  }
}
