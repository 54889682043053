import {
  REQUEST_RESULTS,
  RECEIVE_RESULTS,
  RESULTS_LOADING_FAILED
} from '../actions/results'

const initialState = {
  survey: false,
  loading: false,
  failure: false
}

export default function resultsReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_RESULTS:
      return Object.assign({}, state, {
        loading: true
      })

    case RECEIVE_RESULTS:
      return Object.assign({}, state, {
        loading: false,
        survey: action.survey
      })

    case RESULTS_LOADING_FAILED:
      return Object.assign({}, state, {
        loading: false,
        failure: action.failure
      })
    default:
      return state
  }
}
