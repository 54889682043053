import {
  REQUEST_VERIFICATION,
  RECEIVE_VERIFICATION,
  VERIFICATION_FAILED,
  RECEIVE_TOKEN
} from '../actions/recaptcha'

const initialState = {
  token: false,
  verification: false,
  verifying: false,
  failure: false
}

export default function recaptchaReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_VERIFICATION:
      return Object.assign({}, state, {
        verifying: true
      })

    case RECEIVE_VERIFICATION:
      return Object.assign({}, state, {
        verifying: false,
        verification: action.verification
      })

    case VERIFICATION_FAILED:
      return Object.assign({}, state, {
        verifying: false,
        failure: action.failure
      })
    
    case RECEIVE_TOKEN:
      return Object.assign({}, state, {
        verifying: false,
        failure: action.failure,
        token: action.token
      })

    default:
      return state
  }
}
