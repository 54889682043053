import {
  CREATE_FEEDBACK_FAILED,
  CREATED_FEEDBACK,
  CREATE_FEEDBACK,
  SHOW_FEEDBACK_MODAL,
  HIDE_FEEDBACK_MODAL,
  TOGGLE_FEEDBACK_MODAL
} from '../actions/feedback'

const initialState = {
  failure: false,
  creating: false,
  success: false,
  showModal: false
}

export default function feedbackReducer (state = initialState, action) {
  switch (action.type) {

    case CREATED_FEEDBACK:
      return Object.assign({}, state, {
        creating: false,
        success: true
      })

    case CREATE_FEEDBACK:
      return Object.assign({}, state, {
        creating: true
      })

    case CREATE_FEEDBACK_FAILED:
      return Object.assign({}, state, {
        creating: false,
        failure: action.failure
      })

    case SHOW_FEEDBACK_MODAL:
      return Object.assign({}, state, {
        showModal: true
      })

    case HIDE_FEEDBACK_MODAL:
      return Object.assign({}, state, {
        showModal: false
      })

    case TOGGLE_FEEDBACK_MODAL:
      return Object.assign({}, state, {
        showModal: !state.showModal
      })

    default:
      return state
  }
}
