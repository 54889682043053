import {
  REQUEST_CONTENT,
  RECEIVE_CONTENT,
  CONTENT_FAILED
} from '../actions/content'

const initialState = {
  content: false,
  loading: false,
  failure: false
}

export default function contentReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CONTENT:
      return Object.assign({}, state, {
        loading: true
      })

    case RECEIVE_CONTENT:
      console.log(action.content)
      return Object.assign({}, state, {
        loading: false,
        content: action.content
      })

    case CONTENT_FAILED:
      return Object.assign({}, state, {
        loading: false,
        failure: action.failure
      })

    default:
      return state
  }
}
